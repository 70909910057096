import React, { useEffect } from "react";
import { Link } from "@reach/router";
import Img from "gatsby-image"
import { navigate, StaticQuery, graphql } from "gatsby"
import DotaznikLayout from "../../../components/nabidka/dotaznik/DotaznikLayout";



const Netradicni = ({userData, updateData}) => {
  let uprava = userData;

  function setTyp(typ) {
    uprava.typPohrbu = {
      "otazka": "Typ netradičního pohřbu:",
      "odpoved": typ,
    }

    updateData(uprava);
    navigate("/nabidka/dotaznik/konec");
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          lampiony: file(relativePath: { eq: "lampiony.jpg" }) {
            childImageSharp {
              fixed(width: 320) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
      
          reka: file(relativePath: { eq: "reka.jpg" }) {
            childImageSharp {
              fixed(width: 320) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
      
          priroda: file(relativePath: { eq: "priroda.jpg" }) {
            childImageSharp {
              fixed(width: 320) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
      
          vlastni: file(relativePath: { eq: "vlastni.jpg" }) {
            childImageSharp {
              fixed(width: 320) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <DotaznikLayout phase={2} progress={70}>
          <h1>Jaký typ obřadu vám vyhovuje?</h1>

          <div className="vyberPohrbu">          
            <button className="obrazkovyButton" onClick={() => setTyp("netradicni_lampiony")}>
              <Img className="obrazek" alt="Vypouštění lampionů" fixed={data.lampiony.childImageSharp.fixed} />
              <h2>Vypouštění lampionů</h2>
              <p>Setkání, při kterém budete moci společně s rodinou zavzpomínat na vašeho blízkého během pečlivě nachystané smuteční řeči. Každý potom dostane možnost napsat na lucernu vzkaz, který poputuje do nebe.</p>
              <span className="cena">26 990 Kč</span>
            </button>

            <button className="obrazkovyButton" onClick={() => setTyp("netradicni_reka")}>
              <Img className="obrazek" alt="Rozloučení u řeky" fixed={data.reka.childImageSharp.fixed} />
              <h2>Rozloučení u řeky</h2>
              <p>Rozloučení na klidném místě poblíž řeky. Součástí obřadu je psaní vzkazů na papírové lodičky, které potom pustíte po proudu. Celý program doprovází řečník a vámi vybraná hudba, zakončen je rozptýlením popela do řeky.</p>
              <span className="cena">25 990 Kč</span>
            </button>

            <button className="obrazkovyButton" onClick={() => setTyp("netradicni_priroda")}>
              <Img className="obrazek" alt="Přírodní pohřeb" fixed={data.priroda.childImageSharp.fixed} />
              <h2>Přírodní pohřeb</h2>
              <p>Obřad, který symbolizuje naše spojení s přírodou. Jeho hlavním programem je společné sázení stromu, k jehož kořenům rozptýlíme popel. Druh stromu s vámi předem pečlivě vybíráme podle přáních zesnulého a symboliky.</p>
              <span className="cena">28 990 Kč</span>
            </button>

            <button className="obrazkovyButton" onClick={() => setTyp("netradicni_vlastni")}>
              <Img className="obrazek" alt="Máte vlastní představu?" fixed={data.vlastni.childImageSharp.fixed} />
              <h2>Máte vlastní představu?</h2>
              <p>Máte pocit, že ani jedna z nabízených možností není tou pravou? Vyplňte nezávazný formulář, na základě kterého vás budeme kontaktovat. Společně potom vymyslíme rozloučení přesně podle vašich představ.</p>
              <span className="cena">Cena dohodou</span>
            </button>


          </div>

          <Link to="/nabidka/dotaznik/pohreb" className="zpet">← Zpět</Link>
        </DotaznikLayout>
      )}
    />
  );
};



export default Netradicni